// Website related settings
const settings = {
  isSplash: false,
};

//SEO Related settings
const seo = {
  title: "Sanjeev's Protfolio",
  description:
    "Helllo world , This is Sanjeev a CS Graduate and a tech enthusiast who loves to code.",
  og: {
    title: "Sanjeev Kumar Khatri Portfolio",
  },
};

//Home Page
const greeting = {
  title: "Sanjeev Kumar Khatri",
  logo_name: "Sanjeev kumar Khatri",
  nickname: "Kshetri/Meliza",
  subTitle:
    "Tech enthusiast with a keen interest in Data Analytics, Machine learning and a fervent Salesforce Developer.",
  resumeLink:
    "https://drive.google.com/file/d/1B_cu2uPTAoX10AxrvGZGqto2AsIHFEUX/view?usp=share_link",
  githubProfile: "https://github.com/darkamgel",

};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/darkamgel",
  // linkedin: "https://www.linkedin.com/in/sandeep-kshetri-05bbb6183/",
  // gmail: "Sandeepkshetri3@gmail,com",

  // facebook: "https://www.facebook.com/suraj.khatri.9469/",

  // instagram: "https://www.instagram.com/i_am_rogue_sandeep/"

  {
    name: "Github",
    link: "https://github.com/darkamgel",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/sandeep-kshetri-05bbb6183/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:Sandeepkshetri3@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  {
    name: "Facebook",
    link: "https://www.facebook.com/suraj.khatri.9469/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/i_am_rogue_d_sanjeev/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Data Analytics",
      fileName: "DataScienceImg",
      skills: ["⚡ Statistical analysis, Data cleaning, Visualization, and Spreadsheet",
        "⚡ Knowledge on different Data Analytics programming concepts like R,python and SQL"
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },


        // {
        //   skillName: "Scikit Learn",
        //   fontAwesomeClassname: "logos-pytorch",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Data Sciene And Salesforce Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various Supervised , Unsupervised and Deep learning",
        "⚡ Extensive experience as a Full Stack Developer with a focus on Salesforce technologies like SOQL, Apex, SOSL and Lightning Web Components(LWC).",
        "⚡ strong foundation in JavaScript and Apex applying the knowledge to enhance backend and frontend development capabilities."],
      softwareSkills: [
        {
          skillName: "Data sciene and AI",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            color: "#02569B",
          },
        },
      ],
    },

    // {
    //   title: "Salesforce Developer",
    //   // fileName: "DesignImg",
    //   skills: [
    //     "⚡ Extensive experience as a Full Stack Developer with a focus on Salesforce technologies like SOQL, Apex, SOSL and Lightning Web Components(LWC).",
    //     "⚡ strong foundation in JavaScript and Apex applying the knowledge to enhance backend and frontend development capabilities."
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "SalesForce Developer",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },

    //     {
    //       skillName: "Adobe Illustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/sandeepkshetri3",
    // },

    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/sanjeevkumarkhatri",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Shree Chhorepatan Secondary School",
      subtitle: "+2 Science(High School Degree)",
      logo_path: "school.png",
      alt_name: "Chhorepatan Secondary",
      duration: "2016 - 2018",
      descriptions: [
        "⚡ I have studied basic Course in this Highschool like Physics , Math , chemistry , Biology and English.",
        "⚡ I was selected for NEB Scholarship which is given to top students in college.",
      ],
      website_link: "https://www.chhorepatanss.edu.np",
    },
    {
      title: "Kathmandu University",
      subtitle: "Bsc. in Computer Science",
      logo_path: "KUl.svg.png",
      alt_name: "KU",
      duration: "2018 - 2023",
      descriptions: [
        "⚡ I have studied basic software engineering subjects (Probability and statistics , Data Structure and Algorithms , Object Oriented Programming , System Analysis and Design , Machine Learning , Software Engineering",
        "⚡ Apart from this, I have done courses on Data Analytics, Deep Learning, Data Science and Salesforce(Trailhead)",
      ],
      website_link: "https://ku.edu.np",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Google Data Analytics",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/LRQNBQBKALQ4",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/YYGK786URDVX",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },

    {
      title: "Python",
      subtitle: "- Paul Resnick,Steve Oney ,Jaclyn Cohen",
      logo_path: "python.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/NLGDU4DFB2XC",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Python for Data Science & Machine Learning ",
      subtitle: "- Juan E. Galvan ,Ahmed Wael",
      logo_path: "python.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-bc9ab6e7-8c9d-4646-8407-1db1d994e669/",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    // {
    //   title: "Flutter and Dart",
    //   subtitle: "- Snehal Singh",
    //   logo_path: "flutter.png",
    //   certificate_link:
    //     "https://www.udemy.com/certificate/UC-38652a1d-7742-4714-a114-69b4165c41e4/",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    {
      title: "Git and GitHub.",
      subtitle: "- Bogdan Stashchuk",
      logo_path: "github.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Junior Data Analyst",
          company: "Navya Engineering Solutions",
          logo_path: "navyajpeg.png",
          duration: "April 2022 - January 2023",
          location: "Kathmandu, Nepal",
          description:
            "Acquired foundational knowledge in Data Analysis principles and best practices and Contributed to data collection and data entry from diverse sources of clients. Learn and Utilized data cleaning tools such as Google sheets and SQL to ensure accuracy forensuring reliability. Analyzed basic statistical analyses to identify trends using R and python, Also actively participated in documenting data collection and analysis procedures. Additionally, collaborated with cross-functional teams to integrate analytical findings into comprehensive business strategies.",
          color: "#0879bf",
        },
        {
          title: "Software Enginner",
          company: "Compliance Quest",
          logo_path: "CQ.png",
          duration: "April 2023 - Present",
          location: "Lalitpur, Nepal",
          description:
            "Gained extensive experience as a Full Stack Developer with a focus on Salesforce technologies like SOQL, Apex, SOSL and Lightning Web Components(LWC). Developed expertise in collaborative teamwork, Git version control, Agile methodology, and Jira project management for efficient and adaptive development processes. Acquired a strong foundation in JavaScript and Apex applying the knowledge to enhance backend and frontend development capabilities. Proficient in a variety of full stack development jobs, including data analysis, using Python, and frontend development using TypeScript and React."
          , color: "#0879bf",
        },


      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     // {
    //     //   title: "Data Science Research Intern",
    //     //   company: "Delhivery Pvt. Ltd.",
    //     //   company_url: "https://www.delhivery.com/",
    //     //   logo_path: "delhivery_logo.png",
    //     //   duration: "May 2019 - Sept 2019",
    //     //   location: "Gurgaon, Haryana",
    //     //   description:
    //     //     "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //     //   color: "#ee3c26",
    //     // },

    //   ],
    // },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Rainbow  Volunteer Club Vlounteer",
          company: "Rainbow Volunteer Club",
          company_url: "https://www.facebook.com/rainbowFORlove/",
          logo_path: "rainbow.jpg",
          duration: "June 2019 - Present",
          location: "SrijanTol, Kathmancu",
          description:
            " Worked as a volunteer in this Club . The club is affilated to the original Club from China . We worked in Pandemic time by Providing foods to the poeple and orphans reciding in Kathamndau Valley ",
          color: "#4285F4",
        },




      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is related to Data Analytics and Data Science.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  // title: "Publications",
  // description:
  //   "I have worked on and published a few research papers and publications of my own.",
  // avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "me.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Data Analysis, Machine learning, Salesforce",
  },
  blogSection: {
    // title: "Blogs",
    // subtitle:
    //   "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    // avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Pokhara, Nepal, State - Gandaki, Dist.-Kaski, Chhorepatan - 33800 ",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://mapcarta.com/29457730",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+977 9860587826",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
