import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GithubRepoCard from "../../components/githubRepoCard/GithubRepoCard";
import PublicationCard from "../../components/publicationsCard/PublicationCard";
import Button from "../../components/button/Button";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
import { greeting, projectsHeader, publicationsHeader } from "../../portfolio.js";
import ProjectsData from "../../shared/opensource/projects.json";
import PublicationData from "../../shared/opensource/publications.json";
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";
import img1 from "./assets/book.png";
import img2 from "./assets/html-5.png";
import img3 from "./assets/css.png";
import img4 from "./assets/jupyter.png";
import img5 from "./assets/python.png";
import img6 from "./assets/scikit.png";
import img7 from "./assets/flutter.png";
import img8 from "./assets/firebase.png";
// import  "./Projects.css"

class Projects extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/> */}
                <ProjectsImg theme={theme} />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>


        <div className="container">
      <div className="cardsgrid">
        <a
          href="https://github.com/darkamgel/Meical-Diagnosis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card first">
            <div className="projectlabel">
              <img src={img1} alt="book" className="icon" />
              <span className="projecttitle">Meical-Diagnosis</span>
            </div>

            <span className="projectdescription">
            Developed a natural language processing (NLP) model in Python to analyze and interpret medical symptoms and provide
            diagnoses for patients.Utilized various machine learning algorithms, including Logistic Regression, Bagging Classifier, 
            and Random Forest, to evaluate
            and compare their predictive accuracy, ultimately selecting the most appropriate model for the task.
            Conducted extensive data cleaning and preprocessing to optimize the quality and usefulness of the input data for the machine
            learning models.for more...
             
            </span>
            <div className="dataAndTools">
              <span className="spanCreatedDate">Created on 2022-05-09</span>
              <div className="toolsIcons">
                <img
                  src={img7}
                  alt="htmlImg"
                  className="iconTools"
                />
                <img src={img8} alt="htmlImg" className="iconTools" />
              </div>
            </div>
          </div>
        </a>
        {/* <a
          href="https://github.com/darkamgel/KHANPIN"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card first">
            <div className="projectlabel">
              <img src={img1} alt="book" className="icon" />
              <span className="projecttitle">Khanpin</span>
            </div>

            <span className="projectdescription">
            Khanpin is a CrossPlatform mobile app for ordering and delivering foods.
            Contains two separate login feature for both Customer and Resturant owner with the feature of food rating. for more...
             
            </span>
            <div className="dataAndTools">
              <span className="spanCreatedDate">Created on 2022-05-09</span>
              <div className="toolsIcons">
                <img
                  src={img7}
                  alt="htmlImg"
                  className="iconTools"
                />
                <img src={img8} alt="htmlImg" className="iconTools" />
              </div>
            </div>
          </div>
        </a> */}
        <a
          href="https://github.com/darkamgel/Yatra"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card second">
            <div className="projectlabel">
              <img src={img1} alt="book" className="icon" />
              <span className="projecttitle">Google-Data-Analytics-Case-study-1</span>
            </div>

            <span className="projectdescription">
            Conducted analysis on a provided data set from Google as part of the Google Data Analytics Certificate program, using R for the
analysis. Utilized data cleaning and manipulation techniques in R to prepare the data for analysis, producing documentation of the process.
Generated a summary of the analysis and visualizations in R, as well as three recommendations based on the findings regarding
how annual members and casual riders use Cyclistic bikes differently. for more....
            </span>
            <div className="dataAndTools">
              <span className="spanCreatedDate">Created on 2023-02-25</span>
              <div className="toolsIcons">
                <img
                  src={img7}
                  alt="htmlImg"
                  className="iconTools"
                />
                <img
                  src={img8}
                  alt="htmlImg"
                  className="iconTools"
                />
               
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://github.com/darkamgel/darkamgel-Bulldozer-Price-Prediction-model"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card third">
            <div className="projectlabel">
              <img src={img1} alt="book" className="icon" />
              <span className="projecttitle">Bulldozer-Price-Prediction-model</span>
            </div>

            <span className="projectdescription">
            Model to predict the Bulldozer price using the past selling history.
            This project was part of the kaggle competiton. The model score is in top 30 of that competiton. for more...
            </span>
            <div className="dataAndTools">
              <span className="spanCreatedDate">Created on 2021-06-30</span>
              <div className="toolsIcons">
                <img
                  src={img5}
                  alt="htmlImg"
                  className="iconTools"
                />
                <img
                  src={img4}
                  alt="htmlImg"
                  className="iconTools"
                  style={{marginLeft: "0.5rem"}}
                  
                />
                <img
                  src={img6}
                  alt="htmlImg"
                  className="iconTools"
                  style={{marginLeft: "0.5rem"}}
                  
                />
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://github.com/darkamgel/Heart-Disease-Prediction-model"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card fourth">
            <div className="projectlabel">
              <img src={img1} alt="book" className="icon" />
              <span className="projecttitle">Heart Disease Prediction Model</span>
            </div>

            <span className="projectdescription">
            A simple model to predict Heart Disease of a Patient through their past medical history. for more...
            </span>
            <div className="dataAndTools">
              <span className="spanCreatedDate">Created on 2021-06-12</span>
              <div className="toolsIcons">
                <img
                  src={img5}
                  alt="htmlImg"
                  className="iconTools"
                />
                <img
                  src={img4}
                  alt="htmlImg"
                  className="iconTools"
                  style={{marginLeft: "0.5rem"}}
                  
                />
                <img
                  src={img6}
                  alt="htmlImg"
                  className="iconTools"
                  style={{marginLeft: "0.5rem"}}
                  
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>



        
          
       
        <Button
          text={"More Projects"}
          className="project-button"
          href={greeting.githubProfile}
          newTab={true}
          theme={theme}
        />

        {/* Publications  */}

        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="publications-heading-div">
              <div className="publications-heading-text-div">
                <h1
                  className="publications-heading-text"
                  style={{ color: theme.text }}
                >
                  {publicationsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {publicationsHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>

        

        
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Projects;